import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './ui';

const ButtonNormal = (props) => {
  const {
    renderAs, children, invert, loading, outlined, className, size, ...rest
  } = props;

  return (
    <StyledButton className={`button ${outlined ? 'is-outlined' : ''} ${size ? `is-size-${size}` : ''}  ${loading ? 'is-loading' : ''} ${className}`} as={renderAs || 'button'} {...rest} invert={invert}>
      {children}
    </StyledButton>
  );
};

ButtonNormal.propTypes = {
  renderAs: PropTypes.string,
  children: PropTypes.node.isRequired,
  invert: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.number,
};

ButtonNormal.defaultProps = {
  renderAs: 'a',
  invert: false,
  className: '',
  loading: false,
  size: 40,
};

export default ButtonNormal;
